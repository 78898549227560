import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { sContainer, sTitle, sHtmlList } from '../style'
import Seo from '../components/Seo'
import { useLangContext } from '../context/lang.context'

export default function DocumentTemplate({ data }) {
  const { lang } = useLangContext()
  const document = data.contentfulDokument

  const { title, slug, body, description, noindex } = document

  return (
    <Layout white>
      <Seo title={title} desc={description} url={slug} noIndex={noindex} />
      <section css={sContainer}>
        <h1 css={sTitle}>{title}</h1>
        <article
          css={sHtmlList}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}></article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulDokument(slug: { eq: $slug }) {
      id
      slug
      node_locale
      noindex
      title
      description
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
